import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj7 = () => {
  return (
    <Layout>
      <SEO
        title="Image Processing-Enhance your image"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>
      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-7">
          <div className="py-1-heading">
            <h1>Image Processing-Enhance your image</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Image%20Processing-Enhance%20Your%20Image"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              If we have a mirror image of a newspaper or any other image, and
              want to know whats written on it.
              <br />
              That's what the first task is here,
              <br />
              The Flipping of image.
              <br />
              <br />
              Now for the second task, we have a image from a crime scene,
              having some bullet shots on wall.
              <br />
              We need to observe all possible signs and marks on that wall.
              <br />
              This is all done through "CLAHE"
              <br />
              CLAHE is Contrast Limited Adaptive Histogram Equalization for
              enhancing the local contrast of an image.
              <br />
              Do not worry about the working of CLAHE,
              <br />
              In our case, we are using it only for image enhancement.
              <br />
              <br />
              Simple !!!...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>CV2</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>PIL</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj7
